import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import jwtService from 'jwtService';
import { ActionTypes } from 'constants/index';
import { showMessage, hideMessage, logoutUser, setUserData, submitLoginwithToken, setUserDetailsLoading } from 'store/actions'
// import useIdleTimer from './useIdleTimer';

class Auth extends Component {
    /*eslint-disable-next-line no-useless-constructor*/
    constructor(props) {
        super(props);

        /**
         * Comment the line if you do not use JWt
         */
        this.jwtCheck();

        // Below code will be used to find the idle/active time
        // const { isIdle, activeDuration } = useIdleTimer(
        //     9 * 60 * 1000, // 9 minutes active time
        //     10 * 60 * 1000, // 10 minutes idle time
        //     () => {
        //         console.log("User has been actively using the app for 9 minutes!");
        //         this.onFocus();
        //     },
        //     () => {
        //         console.log("User has been idle for 10 minutes across all tabs!");
        //         jwtService.logout()
        //     }
        // );
        // this.isIdle = isIdle
        // this.activeDuration = activeDuration
    }

    componentDidMount() {
		// Comment to make it work in local
        //TODO: We need to check why it is failing
        // This will execute only when user click inside the page
		// window.addEventListener("focus", this.onFocus)
    }

    onFocus = () => {
        console.log("onFocus - invoked")
        let isSsoLogin = localStorage.getItem('isSsoLogin')
        let isAdLogin = localStorage.getItem('isAdLogin')
        // Refresh token is only required for dSilo registry
        if(!isSsoLogin && !isAdLogin) {
            /**
             * RT should called when its only dSilo registry
             * When in loop
             *  1. RT should be called only when document.visibilityState === "visible"
             *  2. RT should be called if last refresh token in only 10 mins before
             *  3. If user start using the application 
             *  4. then RT should be called for every 9 mins
             *  5. If user is not using the application then he should be logged out.
             *  6. If user leaves the tab
             *      1. then RT should be called
             *  8. If user comes back to the tab
             *      1. if its been less than ten minutes 
             *      2. then RT should not be called
             *      3. then call RT should be called
             *  
             */

            // console.log("this.isIdle ======", this.isIdle)
            // Refresh token
            jwtService.refreshToken();
        }
    }

    jwtCheck = () => {
        jwtService.on('onAutoLogin', () => {

            this.props.showMessage({ message: 'Logging in with JWT' });

            /**
             * Sign in and retrieve user data from Api
             */
            this.props.setUserDetailsLoading(true)
            jwtService.signInWithToken()
                .then(user => {
                    console.log("user", user);
                    if (user) {
                        this.props.submitLoginwithToken(user);
                        this.props.setUserData(user);
                        this.props.showMessage({ message: 'Logged in with JWT' });
                    }
                    this.props.setUserDetailsLoading(false)
                })
                .catch(error => {
                    this.props.showMessage({ message: error });
                    this.props.setUserDetailsLoading(false)
                })
        });

        jwtService.on('onAutoLogout', (message) => {
            if (message) {
                this.props.showMessage({ message });
            }
            this.props.logout();
        });

        jwtService.init();
    };

    render() {
        const { children } = this.props;

        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        logout: logoutUser,
        setUserData: setUserData,
        submitLoginwithToken: submitLoginwithToken,
        showMessage: showMessage,
        hideMessage: hideMessage,
        setUserDetailsLoading: setUserDetailsLoading,
    },
        dispatch);
}

export default connect(null, mapDispatchToProps)(Auth);
