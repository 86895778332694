import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { PersistGate } from 'redux-persist/lib/integration/react';

import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { create } from "jss";

import { ServerStyleSheet, StyleSheetManager } from "styled-components";

import { AppError } from 'dsilo-ui-components';

import App from './App';
import { msalConfig } from './authConfig';
import { register } from './serviceWorker';
import { store, persistor } from 'store/index';

import './index.scss';
import 'react-toastify/dist/ReactToastify.css'

const msalInstance = new PublicClientApplication(msalConfig);

// Retrieve the nonce from the CSP header
const nonce = document
  .querySelector('meta[property="csp-nonce"]')
  ?.getAttribute("content");

// Configure JSS with nonce
const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"), // Ensures styles are applied in the right order
  createGenerateId: () => (rule) => `mui-${rule.key}`, // Generates unique class names
});

// If a nonce exists, apply it to JSS
if (nonce) {
  jss.options.nonce = nonce;
}

// Create a <style> element for style injection
const styleElement = document.createElement("style");
if (nonce) {
  styleElement.setAttribute("nonce", nonce); // Apply CSP nonce
}
document.head.appendChild(styleElement);

ReactDOM.render(
  <StyleSheetManager target={styleElement} disableCSSOMInjection>
    <StylesProvider jss={jss}>
      <Provider store={store}>
        <MsalProvider instance={msalInstance}>
          <PersistGate persistor={persistor}>
            <AppError>
              <DndProvider backend={HTML5Backend}>
                <App />
              </DndProvider>
            </AppError>
          </PersistGate>
        </MsalProvider>
      </Provider>
    </StylesProvider>
  </StyleSheetManager>
  ,
  document.getElementById('root'),
);

register({
  onUpdate: () => { },
});
