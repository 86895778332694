import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Link from '@material-ui/core/Link';
import { styled } from '@material-ui/core/styles';
import dsiloDarkLogo from '../../images/dsilo-logo-dark.png';
import { deleteCookie, getCookie, setCookie } from './utils';
import { Request } from "../../modules/api/clientHelper";

const CustomButton = styled(Button)({
	backgroundColor: '#7900c0',
	border: 'none',
	borderRadius: '100px',
	color: '#fff',
	cursor: 'pointer',
	display: 'flex',
	gap: '48px',
	justifyContent: 'center',
	padding: '8px 32px 8px 32px',
	padding: '16px 48px',
	textTransform: 'none',
	'&:hover': {
		backgroundColor: '#7900c0',
		boxShadow: '0 8px 24px 0 #7900c0',
		textTransform: 'capitalize',
	},
});

const DialogActionCenter = styled(DialogActions)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	gap: '32px',
	marginBottom: '32px',
});

const CookiePolicy = styled(Box)({
	alignItems: 'center',
	display: 'flex',
	flexDirection: 'column',
	gap: '32px',
	justifyContent: 'center',
	margin: '24px',
});

const StyledLink = styled(Link)({
	color: '#7900c0',
	cursor: 'pointer',
	textDecoration: 'none',
	'&:hover': {
		color: '#7900c0',
		textDecoration: 'none',
	},
});

const StyledDailog = styled(Dialog)({
	'& .MuiDialog-paper': {
		borderRadius: '16px',
	},
});

export default function CookieConsent() {
	const [open, setOpen] = useState(false);
	const BASE_URL = process.env.REACT_APP_API_BASE_URL;
	// const [showCookiePolicy, setShowCookiePolicy] = useState(false);

	useEffect(async () => {
		const getUserCookieConsent = async () => {
			try {
				const response = await Request(BASE_URL + '/cc/gucc', {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
				});
				return response;
			} catch (error) {
				console.error('Error fetching user cookie consent:', error);
			}
		};
		const cookieConsent = await getUserCookieConsent() //set-get-ucc  ucc: 1 
		console.log("cookieConsent =====", cookieConsent)
		if (!cookieConsent?.ucc) {
			toggleCookieConsent();
		}
	}, []);

	const handleCookieConsent = async () => {
		const setUserCookieConsentStatus = async () => {
			try {
				const response = await Request(BASE_URL + '/cc/ucc', {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
				});
				console.log(response, 'User cookie consent status');
			} catch (error) {
				console.error('Error retrieving user cookie consent status:', error);
			}
		};
		await setUserCookieConsentStatus()
		// deleteCookie('user_cookie_consent');
		// setCookie('user_cookie_consent', 1, 30);
		toggleCookieConsent();
	};

	const toggleCookieConsent = () => {
		setOpen(prev => !prev);
	};

	return (
		<div>
			<StyledDailog open={open} aria-labelledby="responsive-dialog-title">
				<DialogContent>
					<DialogContentText>
						<CookiePolicy>
							<img loading="lazy" src={dsiloDarkLogo} alt="" />
							<h2>Welcome to dsilo</h2>
							<p>
								We use cookies to personalize content and to provide you with an improved user
								experience. By continuing to browse this site you consent to the use of cookies.
							</p>
							<p>
								Please visit our{' '}
								<StyledLink
									variant="body2"
									target='_blank'
									href='https://dsilo.ai/cookie-policy.php'
								// onClick={() => setShowCookiePolicy(true)}
								>
									Cookie policy
								</StyledLink>{' '}
								for futher details
							</p>
						</CookiePolicy>
					</DialogContentText>
				</DialogContent>
				<DialogActionCenter>
					<CustomButton onClick={handleCookieConsent}>Accept</CustomButton>
					<CustomButton onClick={toggleCookieConsent}>Decline</CustomButton>
				</DialogActionCenter>
			</StyledDailog>
			{/* <CookiePolicyTerms open={showCookiePolicy} handleClose={() => setShowCookiePolicy(false)} /> */}
		</div>
	);
}
